import React, { useState } from "react";
import { Link } from "react-router-dom";

import companyLogo from "../assets/rigsavepng2.png";
import InvestorRelationDropdown from "./dropdown/InvestorRelationDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";
import TranslatorComponent from "./TranslatorComponent";
import ValueEthicsDropdown from "./dropdown/ValueEthicsDropdown";

function Header() {
  const { t } = useTranslation();

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isDropdownVisibleEthics, setDropdownVisibleEthics] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const handleMouseEnterEthics = () => {
    setDropdownVisibleEthics(true);
  };

  const handleMouseLeaveEthics = () => {
    setDropdownVisibleEthics(false);
  };

  return (
    <header className="sticky-top">
      <div className="container">
        <div className="row">
          <div className="col-2 d-flex">
            <Link to={"/"} className="list-inline text-center">
              <img
                src={companyLogo}
                className="company-logo"
                alt="Rigsave SpA logo"
              />
            </Link>
          </div>
          <div className="col-10 d-flex justify-content-end">
            <nav className="navbar navbar-expand">
              <div className="navbar-nav mx-auto">
                <TranslatorComponent />
                <li className="nav-item text-center">
                  <Link
                    to={"/about-us"}
                    className="nav-link text-rigsave-color px-3"
                  >
                    {t("main.header.aboutUs")}
                  </Link>
                </li>
                <li className="nav-item text-center">
                  <Link
                    to={"/governance"}
                    className="nav-link text-rigsave-color px-3"
                  >
                    {t("main.header.governance")}
                  </Link>
                </li>
                <li
                  className="menu nav-item text-center"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <button className="nav-link nav-link-investor text-rigsave-color px-3">
                    {t("main.header.investorRelations")}{" "}
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                  {isDropdownVisible && <InvestorRelationDropdown />}
                </li>
                <li
                  className="menu nav-item text-center"
                  onMouseEnter={handleMouseEnterEthics}
                  onMouseLeave={handleMouseLeaveEthics}
                >
                  <button className="nav-link nav-link-investor text-rigsave-color px-3">
                    {t("values_ethics.title")}{" "}
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                  {isDropdownVisibleEthics && <ValueEthicsDropdown />}
                </li>
                <li className="nav-item text-center">
                  <Link
                    to={"/news-eventi"}
                    className="nav-link text-rigsave-color px-3"
                  >
                    {t("main.header.newsEventi")}
                  </Link>
                </li>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
