import React, { useEffect, useState } from "react";
import BGHero from "./BGHero";
import { Accordion } from "rsuite";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";

function FinancialReports() {
  const { t } = useTranslation();

  const textHero = t("investorSection.bgHero");
  const [ENconsolidatedAudited2021, setENconsolidatedAudited2021] =
    useState("");
  const [ITconsolidatedAudited2021, setITconsolidatedAudited2021] =
    useState("");
  const [ENitalianGAAPAudited2021, setENitalianGAAPAudited2021] = useState("");
  const [ITitalianGAAPAudited2021, setITitalianGAAPAudited2021] = useState("");

  const [ENconsolidatedAudited2022, setENconsolidatedAudited2022] =
    useState("");
  const [ENitalianGAAPAudited2022, setENitalianGAAPAudited2022] = useState("");
  const [ITitalianGAAPAudited2022, setITitalianGAAPAudited2022] = useState("");
  const [ITconsolidatedAudited2022, setITconsolidatedAudited2022] =
    useState("");

  const [ENHalfYearReport, setENHalfYearReport] = useState("");
  const [ITHalfYearReport, setITHalfYearReport] = useState("");
  const [ITConsolidatedAudited2023, setITConsolidatedAudited2023] =
    useState("");
  const [ENConsolidatedAudited2023, setENConsolidatedAudited2023] =
    useState("");
  const [ITItalianGAAPAudited2023, setITItalianGAAPAudited2023] = useState("");

  //TO REMOVE BECAUSE - UP TO 31/12/2023
  //const [ITconsolidatedAudited2024, setITconsolidatedAudited2024] = useState("");

  async function downlaodFiles() {
    setENconsolidatedAudited2021(
      (
        await getUrl({
          key: "financial_reports/2021/EN_Rigsave_Spa_consolidated_IFRS_compliant_2021.pdf",
        })
      ).url.toString()
    );
    setITconsolidatedAudited2021(
      (
        await getUrl({
          key: "financial_reports/2021/Rigsave-rel.-di-revisione-e-bil.-cons.-2021_RC134842021BD4450.pdf",
        })
      ).url.toString()
    );
    setENitalianGAAPAudited2021(
      (
        await getUrl({
          key: "financial_reports/2021/EN_bilancio-separato-rigsave-spa-revisionato-al-31-12-2021_def.pdf",
        })
      ).url.toString()
    );
    setITitalianGAAPAudited2021(
      (
        await getUrl({
          key: "financial_reports/2021/bilancio-separato-rigsave-spa-revisionato-al-31-12-2021.pdf",
        })
      ).url.toString()
    );
    setENconsolidatedAudited2022(
      (
        await getUrl({
          key: "financial_reports/2022/rigsave-spa-audited-consolidated-fs-2022.pdf",
        })
      ).url.toString()
    );
    setENitalianGAAPAudited2022(
      (
        await getUrl({
          key: "financial_reports/2022/EN_bilancio-separato-Rigsave-SpA-revisionato-al-31-12-2022_REV_def.pdf",
        })
      ).url.toString()
    );
    setITitalianGAAPAudited2022(
      (
        await getUrl({
          key: "financial_reports/2022/bilancio-separato-Rigsave-SpA-revisionato-31-12-2022.pdf",
        })
      ).url.toString()
    );
    setITconsolidatedAudited2022(
      (
        await getUrl({
          key: "financial_reports/2022/Rigsave_relazione-revisore-bilancio-consolidato-31.12.2022_ITA-version.pdf",
        })
      ).url.toString()
    );
    setENHalfYearReport(
      (
        await getUrl({
          key: "financial_reports/2023/Rigsave-spa-financial-statement-as-at-30-06-2023.pdf",
        })
      ).url.toString()
    );
    setITHalfYearReport(
      (
        await getUrl({
          key: "financial_reports/2023/Bilancio-semestrale-al-30-06-2023.xbrl_.pdf",
        })
      ).url.toString()
    );
    setITConsolidatedAudited2023(
      (
        await getUrl({
          key: "financial_reports/2023/IFSR_consolidated_audited_ITA.pdf",
        })
      ).url.toString()
    );
    setENConsolidatedAudited2023(
      (
        await getUrl({
          key: "financial_reports/2023/IFSR_consolidated_audited_EN.pdf",
        })
      ).url.toString()
    );
    setITItalianGAAPAudited2023(
      (
        await getUrl({
          key: "financial_reports/2023/bilancio_separato_rigsavespa_revisionato_31_12_2023.pdf",
        })
      ).url.toString()
    );
    /* setITconsolidatedAudited2024(
      (
        await getUrl({
          key: "financial_reports/2024/bilancio_separato_rigsavespa_revisionato_31_12_2023.pdf__English__2024-04-29T17_42_12.pdf",
        })
      ).url.toString()
    ); */
  }
  useEffect(() => {
    downlaodFiles();
  });

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div className="container container-financial-reports text-start pt-2">
        <h3 className="pt-5 pb-3">Financial reports</h3>
        <div className="mb-5">
          <Accordion bordered>
            {/* <Accordion.Panel header="2024" defaultExpanded>
              <a
                href={ITconsolidatedAudited2024}
                target="_blank"
                rel="noreferrer"
              >
                EN consolidated audited 2024{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel> */}
            <Accordion.Panel header="2023" defaultExpanded>
              <a
                href={ITConsolidatedAudited2023}
                target="_blank"
                rel="noreferrer"
              >
                IT IFRS consolidated audited 2023{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={ENConsolidatedAudited2023}
                target="_blank"
                rel="noreferrer"
              >
                EN IFRS consolidated audited 2023{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={ENHalfYearReport} target="_blank" rel="noreferrer">
                EN half year report 2023{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={ITHalfYearReport} target="_blank" rel="noreferrer">
                IT half year report 2023{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={ITItalianGAAPAudited2023}
                target="_blank"
                rel="noreferrer"
              >
                IT Italian GAAP Audited 2023{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel>
            <Accordion.Panel header="2022">
              <a
                href={ENconsolidatedAudited2022}
                target="_blank"
                rel="noreferrer"
              >
                EN consolidated audited 2022
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={ITconsolidatedAudited2022}
                target="_blank"
                rel="noreferrer"
              >
                IT consolidated audited 2022
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={ENitalianGAAPAudited2022}
                target="_blank"
                rel="noreferrer"
              >
                EN italian GAAP audited 2022
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={ITitalianGAAPAudited2022}
                target="_blank"
                rel="noreferrer"
              >
                IT italian GAAP audited 2022
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel>
            <Accordion.Panel header="2021">
              <a
                href={ENconsolidatedAudited2021}
                target="_blank"
                rel="noreferrer"
              >
                EN consolidated audited 2021
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={ITconsolidatedAudited2021}
                target="_blank"
                rel="noreferrer"
              >
                IT consolidated audited 2021
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={ENitalianGAAPAudited2021}
                target="_blank"
                rel="noreferrer"
              >
                EN italian GAAP audited 2021
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={ITitalianGAAPAudited2021}
                target="_blank"
                rel="noreferrer"
              >
                IT italian GAAP audited 2021
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default FinancialReports;
