import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PubblicationsDropdown = () => {
  const { t } = useTranslation();

  return (
    <div className="dropdown-menu menu-pubblications navbar-nav mx-auto pt-2">
      <ul className="p-0">
        <li className="nav-link text-rigsave-color">
          <Link to={"/presentations"} className="text-rigsave-color">
            {t(
              "main.header.subheaderInvestor.subheaderPubblications.presentations"
            )}
          </Link>
        </li>
        <li className="nav-link text-rigsave-color">
          <Link to={"/director-dealings"} className="text-rigsave-color">
            {t(
              "main.header.subheaderInvestor.subheaderPubblications.directorDealings"
            )}
          </Link>
        </li>
        <li className="nav-link text-rigsave-color">
          <Link to={"/financial-reports"} className="text-rigsave-color">
            {t(
              "main.header.subheaderInvestor.subheaderPubblications.financialReports"
            )}
          </Link>
        </li>
        <li className="nav-link text-rigsave-color">
          <Link to={"/analyst-reports"} className="text-rigsave-color">
            {t(
              "main.header.subheaderInvestor.subheaderPubblications.analystReport"
            )}
          </Link>
        </li>
        <li className="nav-link text-rigsave-color">
          <Link to={"/financial-calendar"} className="text-rigsave-color">
            {t(
              "main.header.subheaderInvestor.subheaderPubblications.financialCalendar"
            )}
          </Link>
        </li>
        <li className="nav-link text-rigsave-color">
          <Link to={"/inclusion-document"} className="text-rigsave-color">
            {t(
              "main.header.subheaderInvestor.subheaderPubblications.inclusionDocument"
            )}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default PubblicationsDropdown;
