import React, { useEffect, useState } from "react";
import BGHero from "./BGHero";
import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function Model231() {
  const { t } = useTranslation();

  const textHero = t("investorSection.bgHero");

  const [a2, setA2] = useState("");
  const [a3, setA3] = useState("");
  const [a4, setA4] = useState("");
  const [a5, setA5] = useState("");
  const [a6, setA6] = useState("");

  async function downlaodFile() {
    setA2(
      (
        await getUrl({
          key: "model_231/A2.pdf",
        })
      ).url.toString()
    );
    setA3(
      (
        await getUrl({
          key: "model_231/A3.pdf",
        })
      ).url.toString()
    );
    setA4(
      (
        await getUrl({
          key: "model_231/A4.pdf",
        })
      ).url.toString()
    );
    setA5(
      (
        await getUrl({
          key: "model_231/A5.pdf",
        })
      ).url.toString()
    );
    setA6(
      (
        await getUrl({
          key: "model_231/A6.pdf",
        })
      ).url.toString()
    );
  }

  useEffect(() => {
    downlaodFile();
  });

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div className="container container-azioni-soc text-start">
        <h3 className="pt-5">{t("values_ethics.model_231")}</h3>
        <div className="container mt-4 mb-5 p-0">
          <a
            href={a2}
            target="_blank"
            className="d-block pb-3"
            rel="noreferrer"
          >
            {t("values_ethics.model_231")} {"-1"}
            <FontAwesomeIcon className="ps-2" icon={faDownload} />
          </a>
          <a
            href={a3}
            target="_blank"
            className="d-block pb-3"
            rel="noreferrer"
          >
            {t("values_ethics.model_231")} {"-2"}
            <FontAwesomeIcon className="ps-2" icon={faDownload} />
          </a>
          <a
            href={a4}
            target="_blank"
            className="d-block pb-3"
            rel="noreferrer"
          >
            {t("values_ethics.model_231")} {"-3"}
            <FontAwesomeIcon className="ps-2" icon={faDownload} />
          </a>
          <a
            href={a5}
            target="_blank"
            className="d-block pb-3"
            rel="noreferrer"
          >
            {t("values_ethics.model_231")} {"-4"}
            <FontAwesomeIcon className="ps-2" icon={faDownload} />
          </a>
          <a
            href={a6}
            target="_blank"
            className="d-block pb-3"
            rel="noreferrer"
          >
            {t("values_ethics.model_231")} {"-5"}
            <FontAwesomeIcon className="ps-2" icon={faDownload} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Model231;
