import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ValueEthicsDropdown = () => {
  const { t } = useTranslation();

  return (
    <div className="dropdown-menu navbar-nav mx-auto pt-2">
      <ul className="nav-link-pubblications m-0 p-0">
        <li className="nav-link text-rigsave-color">
          <Link to={"/ethical-code"} className="text-rigsave-color">
            {t("values_ethics.ethic_code")}
          </Link>
        </li>
        <li className="nav-link text-rigsave-color">
          <Link to={"/model-231"} className="text-rigsave-color">
            {t("values_ethics.model_231")}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ValueEthicsDropdown;
