import React, { useEffect, useState } from "react";
import BGHero from "./BGHero";

import "../css/AboutUs.css";

import chiSiamoImg from "../assets/Chi-siamo-Strategia.png";
import { ReactComponent as SvgIcon1 } from "../assets/Chi-siamo-Strategia-n1-1.svg";
import { ReactComponent as SvgIcon2 } from "../assets/Chi-siamo-Strategia-n2-1.svg";
import { ReactComponent as SvgIcon3 } from "../assets/Chi-siamo-Strategia-n3.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { Trans, useTranslation } from "react-i18next";

import { getUrl } from "aws-amplify/storage";

function AboutUs() {
  const { t } = useTranslation();

  const textAboutUs = t("aboutUsSection.bgHero");
  const subtext = t("aboutUsSection.bgHeroSubtext");

  const [pdfCompanyData, setPdfCompanyData] = useState("");

  async function downlaodFile() {
    setPdfCompanyData(
      (
        await getUrl({
          key: "generic/Rigsave-Capital-Ltd_ESG-Policy_FINAL_08112021.pdf",
        })
      ).url.toString()
    );
  }

  useEffect(() => {
    downlaodFile();
  });

  return (
    <div id="aboutUs">
      <BGHero params={{ text: textAboutUs, subtext: subtext }} />
      <div className="container container-about-us d-flex justify-content-center align-items-center pt-5 pb-5">
        <div className="card">
          <div className="card-body">
            <h3 className="pt-4 pb-3">{t("aboutUsSection.rigsaveGroup")}</h3>
            <div className="card-text pb-4 px-5">
              <Trans>{t("aboutUsSection.rigsaveGroupDescription")}</Trans>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-lg-flex strategy-cont pt-5 pb-5">
        <img src={chiSiamoImg} alt="Chi siamo" />
        <div className="pt-3 ms-4">
          <h3>{t("aboutUsSection.strategy")}</h3>
          <p>{t("aboutUsSection.strategyDescription")}</p>
          <a href={pdfCompanyData} target="_blank" rel="noreferrer">
            Download <FontAwesomeIcon icon={faDownload} />
          </a>
        </div>
      </div>
      <div className="container valori-cont d-lg-flex justify-content-center pt-5 pb-5">
        <div className="col-4 px-5">
          <SvgIcon1 />
          <h3 className="pt-3 pb-3">{t("aboutUsSection.userExperience")}</h3>
          <p>{t("aboutUsSection.userExperienceSection")}</p>
        </div>
        <div className="col-4 px-5">
          <SvgIcon2 />
          <h3 className="pt-3 pb-3">
            {t("aboutUsSection.transparencyIndipendence")}
          </h3>
          <p>{t("aboutUsSection.transparencyIndipendenceSection")}</p>
        </div>
        <div className="col-4 px-5">
          <SvgIcon3 />
          <h3 className="pt-3 pb-3">{t("aboutUsSection.innovation")}</h3>
          <p>{t("aboutUsSection.innovationSection")}</p>
        </div>
      </div>
      <div className="container filosofia-cont justify-content-center pt-5">
        <h3 className="pt-2 pb-2">{t("aboutUsSection.philosophy")}</h3>
        <p>{t("aboutUsSection.philosophyDescription")}</p>
        <div className="d-lg-flex justify-content-center pt-5">
          <div className="d-flex align-items-center cont-trasp container-img justify-content-center">
            <div className="align-middle">
              <h3 className="pb-2">{t("aboutUsSection.transparency")}</h3>
              <p>{t("aboutUsSection.transparencyDescription")}</p>
            </div>
          </div>
          <div className="d-flex align-items-center cont-conc container-img justify-content-center">
            <div className="align-middle">
              <h3 className="pb-2">{t("aboutUsSection.concreteness")}</h3>
              <p>{t("aboutUsSection.concretenessDescription")}</p>
            </div>
          </div>
          <div className="d-flex align-items-center cont-inn container-img justify-content-center">
            <div className="align-middle">
              <h3 className="pb-2">{t("aboutUsSection.innovation")}</h3>
              <p>{t("aboutUsSection.innovationDescription")}</p>
            </div>
          </div>
          <div className="d-flex align-items-center cont-incl container-img justify-content-center">
            <div className="align-middle">
              <h3 className="pb-2">{t("aboutUsSection.inclusion")}</h3>
              <p>{t("aboutUsSection.inclusionDescription")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
