import React, { useRef } from "react";
import BGHero from "./BGHero";
import { useTranslation } from "react-i18next";

function UfficioStampa() {
  const { t } = useTranslation();

  const textHero = t("investorSection.bgHero");

  const form = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.target.reset();
  };

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div className="container container-ufficio-stampa text-start">
        <h3 className="pt-5 pb-3">{t("pressOfficeSection.pressOffice")}</h3>
        <div>
          <form ref={form} onSubmit={handleSubmit} autoComplete="off">
            <fieldset disabled="disabled">
              <p className="mt-2 mb-0">{t("pressOfficeSection.name")}</p>
              <input
                className="px-2 pt-2 pb-2 mt-1 mb-2"
                name="name"
                placeholder={t("pressOfficeSection.name")}
                required
              />
              <p className="mt-2 mb-0">Email</p>
              <input
                type="email"
                className="px-2 pt-2 pt-2 pb-2 mt-1 mb-2"
                name="email"
                placeholder="Email"
                required
              />
              <p className="mt-2 mb-0">{t("pressOfficeSection.message")}</p>
              <textarea
                className="px-2 pt-2 mt-1 mb-4"
                name="subject"
                placeholder={t("pressOfficeSection.message")}
                required
              />
              <button className="mb-5 pt-3 pb-3" type="submit" disabled>
                {t("pressOfficeSection.send")}
              </button>
            </fieldset>
          </form>
        </div>
        <div className="text-center">
          <h3 className="text-center">
            {t("pressOfficeSection.labelInfo")} ir@rigsave.com
          </h3>
        </div>
      </div>
    </div>
  );
}

export default UfficioStampa;
