import React from "react";
import BGHero from "./BGHero";

import heroImg from "../assets/Governance-Hero.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NewsEventi() {
  const { t } = useTranslation();

  const textHero = t("main.header.newsEventi");

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div className="container-fluid row align-items-center container-news text-start mt-5 mb-5 pb-4">
        <div className="col-4 mt-5 pt-3 pb-3 px-3">
          <Link to={"/news-eventi/mandate_17_pooling_vehicles"}>
            <div className="card">
              <img className="card-img-top" src={heroImg} alt="Hero news" />
              <div className="card-body pt-4 pb-4 px-4">
                <h5 className="pt-2 pb-2 card-title">
                  {t("news.title.mandate_17_pooling_vehicles")}
                </h5>
                <p className="card-text">
                  {t("news.readMore")}{" "}
                  <FontAwesomeIcon className="pt-1" icon={faAnglesRight} />
                </p>
              </div>
              <div className="card-footer px-4 pt-2 pb-2">
                <p className="mb-0">07/06/2024</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-4 mt-5 pt-3 pb-3 px-3">
          <Link
            to={
              "/news-eventi/rigsave-s-p-a-quotazione-alla-borsa-di-francoforte"
            }
          >
            <div className="card">
              <img className="card-img-top" src={heroImg} alt="Hero news" />
              <div className="card-body pt-4 pb-4 px-4">
                <h5 className="pt-2 pb-2 card-title">
                  {t(
                    "news.title.rigsave-s-p-a-quotazione-alla-borsa-di-francoforte"
                  )}
                </h5>
                <p className="card-text">
                  {t("news.readMore")}{" "}
                  <FontAwesomeIcon className="pt-1" icon={faAnglesRight} />
                </p>
              </div>
              <div className="card-footer px-4 pt-2 pb-2">
                <p className="mb-0">14/12/2023</p>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-4 mt-5 pt-3 pb-3 px-3">
          <Link to={"/news-eventi/rigsave_quotazione_francoforte"}>
            <div className="card">
              <img className="card-img-top" src={heroImg} alt="Hero news" />
              <div className="card-body pt-4 pb-4 px-4">
                <h5 className="pt-2 pb-2 card-title">
                  {t("news.title.rigsave_quotazione_francoforte")}
                </h5>
                <p className="card-text">
                  {t("news.readMore")}{" "}
                  <FontAwesomeIcon className="pt-1" icon={faAnglesRight} />
                </p>
              </div>
              <div className="card-footer px-4 pt-2 pb-2">
                <p className="mb-0">28/11/2023</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-4 mt-5 pt-3 pb-3 px-3">
          <Link to={"/news-eventi/finance-night-giugno-2022"}>
            <div className="card">
              <img className="card-img-top" src={heroImg} alt="Hero news" />
              <div className="card-body pt-4 pb-4 px-4">
                <h5 className="pt-2 pb-2 card-title">
                  {t("news.title.finance-night-giugno-2022")}
                </h5>
                <p className="card-text">
                  {t("news.readMore")}{" "}
                  <FontAwesomeIcon className="pt-1" icon={faAnglesRight} />
                </p>
              </div>
              <div className="card-footer px-4 pt-2 pb-2">
                <p className="mb-0">29/06/2022</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-4 mt-5 pt-3 pb-3 px-3">
          <Link to={"/news-eventi/circolo-delle-imprese"}>
            <div className="card">
              <img className="card-img-top" src={heroImg} alt="Hero news" />
              <div className="card-body pt-4 pb-4 px-4">
                <h5 className="pt-2 pb-2 card-title">
                  {t("news.title.circolo-delle-imprese")}
                </h5>
                <p className="card-text">
                  {t("news.readMore")}{" "}
                  <FontAwesomeIcon className="pt-1" icon={faAnglesRight} />
                </p>
              </div>
              <div className="card-footer px-4 pt-2 pb-2">
                <p className="mb-0">11/05/2022</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-4 mt-5 pt-3 pb-3 px-3">
          <Link to={"/news-eventi/finanza-servizio-di-tutti"}>
            <div className="card">
              <img className="card-img-top" src={heroImg} alt="Hero news" />
              <div className="card-body pt-4 pb-4 px-4">
                <h5 className="pt-2 pb-2 card-title">
                  {t("news.title.finanza-servizio-di-tutti")}
                </h5>
                <p className="card-text">
                  {t("news.readMore")}{" "}
                  <FontAwesomeIcon className="pt-1" icon={faAnglesRight} />
                </p>
              </div>
              <div className="card-footer px-4 pt-2 pb-2">
                <p className="mb-0">07/04/2022</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NewsEventi;
