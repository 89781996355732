import React, { useEffect, useState } from "react";
import BGHero from "./BGHero";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion } from "rsuite";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { getUrl } from "aws-amplify/storage";

function DirectorDealings() {
  const { t } = useTranslation();

  const textHero = t("investorSection.bgHero");

  const [sGervasiDDealings1, setsGervasiDDealings1] = useState("");
  const [sGervasiDDealings2, setsGervasiDDealings2] = useState("");
  const [sGervasiDDealings3, setsGervasiDDealings3] = useState("");
  const [sGervasiDDealings4, setsGervasiDDealings4] = useState("");
  const [sGervasiDDealings5, setsGervasiDDealings5] = useState("");
  const [sGervasiDDealings6, setsGervasiDDealings6] = useState("");
  const [sGervasiDDealings7, setsGervasiDDealings7] = useState("");
  const [sGervasiDDealings8, setsGervasiDDealings8] = useState("");
  const [sGervasiDDealings9, setsGervasiDDealings9] = useState("");
  const [sGervasiDDealings10, setsGervasiDDealings10] = useState("");
  const [sGervasiDDealings11, setsGervasiDDealings11] = useState("");
  const [sGervasiDDealings100524, setsGervasiDDealings100524] = useState("");
  const [sGervasiDDealings130524, setsGervasiDDealings130524] = useState("");
  const [sGervasiDDealings140524, setsGervasiDDealings140524] = useState("");
  const [sGervasiDDealings150524, setsGervasiDDealings150524] = useState("");
  const [sGervasiDDealingsAmendment, setsGervasiDDealingsAmendment] =
    useState("");

  const [mBasilicataDDealings1, setmBasilicataDDealings1] = useState("");
  const [mBasilicataDDealings2, setmBasilicataDDealings2] = useState("");
  const [mBasilicataDDealings3, setmBasilicataDDealings3] = useState("");
  const [mBasilicataDDealings4, setmBasilicataDDealings4] = useState("");
  const [mBasilicataDDealings5, setmBasilicataDDealings5] = useState("");
  const [mBasilicataDDealings6, setmBasilicataDDealings6] = useState("");
  const [mBasilicataDDealings7, setmBasilicataDDealings7] = useState("");
  const [mBasilicataDDealings8, setmBasilicataDDealings8] = useState("");
  const [mBasilicataDDealings9, setmBasilicataDDealings9] = useState("");
  const [mBasilicataDDealings10, setmBasilicataDDealings10] = useState("");
  const [mBasilicataDDealings11, setmBasilicataDDealings11] = useState("");
  const [mBasilicataDDealings12, setmBasilicataDDealings12] = useState("");
  const [mBasilicataDDealings13, setmBasilicataDDealings13] = useState("");

  const [redAntCapital, setredAntCapital] = useState("");
  const [redAntCapital1, setredAntCapital1] = useState("");
  const [redAntCapital2, setredAntCapital2] = useState("");
  const [redAntCapital3, setredAntCapital3] = useState("");
  const [redAntCapital150524, setredAntCapital150524] = useState("");
  const [redAntCapital170524, setredAntCapital170524] = useState("");

  const [rigsaveAbsoluteAlphaFund, setRigsaveAbsoluteAlphaFund] = useState("");
  const [rigsaveAbsoluteAlphaFund1, setRigsaveAbsoluteAlphaFund1] =
    useState("");
  const [rigsaveAbsoluteAlphaFund2, setRigsaveAbsoluteAlphaFund2] =
    useState("");
  const [rigsaveAbsoluteAlphaFund3, setRigsaveAbsoluteAlphaFund3] =
    useState("");
  const [rigsaveAbsoluteAlphaFund4, setRigsaveAbsoluteAlphaFund4] =
    useState("");
  const [rigsaveAbsoluteAlphaFund5, setRigsaveAbsoluteAlphaFund5] =
    useState("");
  const [rigsaveAbsoluteAlphaFund6, setRigsaveAbsoluteAlphaFund6] =
    useState("");
  const [rigsaveAbsoluteAlphaFund7, setRigsaveAbsoluteAlphaFund7] =
    useState("");
  const [rigsaveAbsoluteAlphaFund8, setRigsaveAbsoluteAlphaFund8] =
    useState("");
  const [rigsaveAbsoluteAlphaFund160524, setRigsaveAbsoluteAlphaFund160524] =
    useState("");
  const [rigsaveAbsoluteAlphaFund240524, setRigsaveAbsoluteAlphaFund240524] =
    useState("");
  const [rigsaveAbsoluteAlphaFund270524, setRigsaveAbsoluteAlphaFund270524] =
    useState("");

  const [rigsaveFundSICAV, setRigsaveFundSICAV] = useState("");
  const [rigsaveFundSICAV1, setRigsaveFundSICAV1] = useState("");
  const [rigsaveFundSICAV270524, setRigsaveFundSICAV270524] = useState("");
  const [rigsaveFundSICAV090524, setRigsaveFundSICAV090524] = useState("");
  const [rigsaveFundSICAV090524_2, setRigsaveFundSICAV090524_2] = useState("");

  async function downlaodFiles() {
    setsGervasiDDealings1(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/gervasi_1.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings2(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/gervasi_2.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings3(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/gervasi_3.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings4(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/gervasi_4.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings5(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/gervasi_5.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings6(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/gervasi_6.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings7(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/gervasi_7.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings8(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/gervasi_8.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings9(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/gervasi_9.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings10(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/gervasi_10.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings11(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/gervasi_11.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings100524(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/2024_05_10 gervasi.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings130524(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/2024_05_13_gervasi.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings140524(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/2024_05_14_gervasi.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealings150524(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/2024_05_15_gervasi.pdf",
        })
      ).url.toString()
    );
    setsGervasiDDealingsAmendment(
      (
        await getUrl({
          key: "director_dealings/2023/s_gervasi/sg_amendment.pdf",
        })
      ).url.toString()
    );

    setmBasilicataDDealings1(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_1.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings2(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_2.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings3(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_3.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings4(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_4.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings5(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_5.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings6(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_6.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings7(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_7.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings8(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_8.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings9(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_9.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings10(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_10.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings11(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_11.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings12(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_12.pdf",
        })
      ).url.toString()
    );
    setmBasilicataDDealings13(
      (
        await getUrl({
          key: "director_dealings/2023/m_basilicata/basilicata_13.pdf",
        })
      ).url.toString()
    );
    setredAntCapital(
      (
        await getUrl({
          key: "director_dealings/2023/red_ant_capital/red_ant_capital_srl.pdf",
        })
      ).url.toString()
    );
    setredAntCapital1(
      (
        await getUrl({
          key: "director_dealings/2023/red_ant_capital/red_ant_capital_srl1.pdf",
        })
      ).url.toString()
    );
    setredAntCapital2(
      (
        await getUrl({
          key: "director_dealings/2023/red_ant_capital/red_ant_capital_srl2.pdf",
        })
      ).url.toString()
    );
    setredAntCapital3(
      (
        await getUrl({
          key: "director_dealings/2023/red_ant_capital/red_ant_capital_srl3.pdf",
        })
      ).url.toString()
    );
    setredAntCapital150524(
      (
        await getUrl({
          key: "director_dealings/2023/red_ant_capital/2024_05_15_red_ant.pdf",
        })
      ).url.toString()
    );
    setredAntCapital170524(
      (
        await getUrl({
          key: "director_dealings/2023/red_ant_capital/2024_05_17_red_ant.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund1(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund1.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund2(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund2.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund3(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund3.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund4(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund4.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund5(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund5.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund6(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund6.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund7(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund7.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund8(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/rigsave_absolute_alpha_fund8.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund160524(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/2024_05_16_raaf.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund240524(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/2024_05_24_raaf.pdf",
        })
      ).url.toString()
    );
    setRigsaveAbsoluteAlphaFund270524(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_absolute_alpha_fund/2024_05_27_raaf.pdf",
        })
      ).url.toString()
    );
    setRigsaveFundSICAV(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_fund_sicav/rigsave_fund_sicav.pdf",
        })
      ).url.toString()
    );
    setRigsaveFundSICAV1(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_fund_sicav/rigsave_fund_sicav1.pdf",
        })
      ).url.toString()
    );
    setRigsaveFundSICAV270524(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_fund_sicav/2024_05_07_sicav.pdf",
        })
      ).url.toString()
    );
    setRigsaveFundSICAV090524(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_fund_sicav/2024_05_09_sicav.pdf",
        })
      ).url.toString()
    );
    setRigsaveFundSICAV090524_2(
      (
        await getUrl({
          key: "director_dealings/2023/rigsave_fund_sicav/2024_05_09_sicav_2.pdf",
        })
      ).url.toString()
    );
  }
  useEffect(() => {
    downlaodFiles();
  });

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div className="container container-dealings text-start pt-2">
        <h3 className="pt-5 pb-3 text-start">Director dealings</h3>
        <h5 className="pb-3 text-start">2024</h5>
        <div className="mb-5">
          <Accordion bordered>
            <Accordion.Panel header="Salvatore Gervasi" defaultExpanded>
              <a
                href={sGervasiDDealings100524}
                target="_blank"
                rel="noreferrer"
              >
                Salvatore Gervasi - 10 {t("investorSection.may")} 2024{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={sGervasiDDealings130524}
                target="_blank"
                rel="noreferrer"
              >
                Salvatore Gervasi - 13 {t("investorSection.may")} 2024{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={sGervasiDDealings140524}
                target="_blank"
                rel="noreferrer"
              >
                Salvatore Gervasi - 14 {t("investorSection.may")} 2024{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={sGervasiDDealings150524}
                target="_blank"
                rel="noreferrer"
              >
                Salvatore Gervasi - 15 {t("investorSection.may")} 2024{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sGervasiDDealings1} target="_blank" rel="noreferrer">
                Salvatore Gervasi - 26 {t("investorSection.march")} 2024 - 1{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sGervasiDDealings2} target="_blank" rel="noreferrer">
                Salvatore Gervasi - 26 {t("investorSection.march")} 2024 - 2{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sGervasiDDealings3} target="_blank" rel="noreferrer">
                Salvatore Gervasi - 26 {t("investorSection.march")} 2024 - 3{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sGervasiDDealings4} target="_blank" rel="noreferrer">
                Salvatore Gervasi - 26 {t("investorSection.march")} 2024 - 4{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sGervasiDDealings5} target="_blank" rel="noreferrer">
                Salvatore Gervasi - 26 {t("investorSection.march")} 2024 - 5{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sGervasiDDealings6} target="_blank" rel="noreferrer">
                Salvatore Gervasi - 26 {t("investorSection.march")} 2024 - 6{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sGervasiDDealings7} target="_blank" rel="noreferrer">
                Salvatore Gervasi - 26 {t("investorSection.march")} 2024 - 7{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sGervasiDDealings8} target="_blank" rel="noreferrer">
                Salvatore Gervasi - 26 {t("investorSection.march")} 2024 - 8{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sGervasiDDealings9} target="_blank" rel="noreferrer">
                Salvatore Gervasi - 26 {t("investorSection.march")} 2024 - 9{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sGervasiDDealings10} target="_blank" rel="noreferrer">
                Salvatore Gervasi - 23 {t("investorSection.april")} 2024{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sGervasiDDealings11} target="_blank" rel="noreferrer">
                Salvatore Gervasi - 30 {t("investorSection.april")} 2024{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={sGervasiDDealingsAmendment}
                target="_blank"
                rel="noreferrer"
              >
                Salvatore Gervasi - 29 {t("investorSection.march")} 2024{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel>
            <Accordion.Panel header="Michele Basilicata">
              <a href={mBasilicataDDealings1} target="_blank" rel="noreferrer">
                Michele Basilicata - 26 {t("investorSection.march")} 2024 - 1{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings2} target="_blank" rel="noreferrer">
                Michele Basilicata - 26 {t("investorSection.march")} 2024 - 2{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings3} target="_blank" rel="noreferrer">
                Michele Basilicata - 26 {t("investorSection.march")} 2024 - 3{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings4} target="_blank" rel="noreferrer">
                Michele Basilicata - 26 {t("investorSection.march")} 2024 - 4{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings5} target="_blank" rel="noreferrer">
                Michele Basilicata - 26 {t("investorSection.march")} 2024 - 5{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings6} target="_blank" rel="noreferrer">
                Michele Basilicata - 26 {t("investorSection.march")} 2024 - 6{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings7} target="_blank" rel="noreferrer">
                Michele Basilicata - 26 {t("investorSection.march")} 2024 - 7{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings8} target="_blank" rel="noreferrer">
                Michele Basilicata - 26 {t("investorSection.march")} 2024 - 8{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings9} target="_blank" rel="noreferrer">
                Michele Basilicata - 26 {t("investorSection.march")} 2024 - 9{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings13} target="_blank" rel="noreferrer">
                Michele Basilicata - 10 {t("investorSection.april")} 2024{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings10} target="_blank" rel="noreferrer">
                Michele Basilicata - 23 {t("investorSection.april")} 2024 - 1{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings11} target="_blank" rel="noreferrer">
                Michele Basilicata - 23 {t("investorSection.april")} 2024 - 2{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={mBasilicataDDealings12} target="_blank" rel="noreferrer">
                Michele Basilicata - 23 {t("investorSection.april")} 2024 - 3{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel>
            <Accordion.Panel header="Andrea Polzot / Red Ant Capital">
              <a href={redAntCapital150524} target="_blank" rel="noreferrer">
                Red Ant Capital Srl - 15 {t("investorSection.may")} 2024
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={redAntCapital170524} target="_blank" rel="noreferrer">
                Red Ant Capital Srl - 17 {t("investorSection.may")} 2024
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={redAntCapital} target="_blank" rel="noreferrer">
                Red Ant Capital Srl - 21 {t("investorSection.february")} 2024
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={redAntCapital1} target="_blank" rel="noreferrer">
                Red Ant Capital Srl - 16 {t("investorSection.january")} 2024
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={redAntCapital3} target="_blank" rel="noreferrer">
                Red Ant Capital Srl - 29 {t("investorSection.december")} 2023
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={redAntCapital2} target="_blank" rel="noreferrer">
                Red Ant Capital Srl - 22 {t("investorSection.december")} 2023
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel>
            <Accordion.Panel header="Andrea Polzot / Rigsave Absolute Alpha Fund">
              <a
                href={rigsaveAbsoluteAlphaFund160524}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 16 {t("investorSection.may")}{" "}
                2024 - 2
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveAbsoluteAlphaFund240524}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 24 {t("investorSection.may")}{" "}
                2024 - 2
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveAbsoluteAlphaFund270524}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 27 {t("investorSection.may")}{" "}
                2024 - 2
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveAbsoluteAlphaFund7}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 13 {t("investorSection.may")}{" "}
                2024 - 1
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveAbsoluteAlphaFund8}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 13 {t("investorSection.may")}{" "}
                2024 - 2
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveAbsoluteAlphaFund5}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 23{" "}
                {t("investorSection.april")} 2024 - 2
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveAbsoluteAlphaFund6}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 23{" "}
                {t("investorSection.april")} 2024 - 1
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveAbsoluteAlphaFund}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 10{" "}
                {t("investorSection.april")} 2024
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveAbsoluteAlphaFund1}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 04{" "}
                {t("investorSection.april")} 2024 - 1
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveAbsoluteAlphaFund4}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 04{" "}
                {t("investorSection.april")} 2024 - 2
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveAbsoluteAlphaFund2}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 12{" "}
                {t("investorSection.march")} 2024
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveAbsoluteAlphaFund3}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Absolute Alpha Fund SCSp - 05{" "}
                {t("investorSection.march")} 2024
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel>
            <Accordion.Panel header="Rigsave Fund SICAV">
              <a href={rigsaveFundSICAV270524} target="_blank" rel="noreferrer">
                Rigsave Fund SICAV - 27 {t("investorSection.may")} 2024
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={rigsaveFundSICAV090524} target="_blank" rel="noreferrer">
                Rigsave Fund SICAV - 09 {t("investorSection.may")} 2024
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={rigsaveFundSICAV090524_2}
                target="_blank"
                rel="noreferrer"
              >
                Rigsave Fund SICAV - 09 {t("investorSection.may")} 2024 - 2
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={rigsaveFundSICAV} target="_blank" rel="noreferrer">
                Rigsave Fund SICAV - 21 {t("investorSection.february")} 2024
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={rigsaveFundSICAV1} target="_blank" rel="noreferrer">
                Rigsave Fund SICAV - 16 {t("investorSection.january")} 2024
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default DirectorDealings;
