import React, { useEffect, useState } from "react";
import BGHero from "./BGHero";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";

function InclusionDocument() {
  const { t } = useTranslation();

  const textHero = t("investorSection.bgHero");

  const [pdfPresentation, setPdfPresentation] = useState("");

  async function downlaodFile() {
    setPdfPresentation(
      (
        await getUrl({
          key: "generic/Rigsave_Inclusion_Document.pdf",
        })
      ).url.toString()
    );
  }
  useEffect(() => {
    downlaodFile();
  });

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div
        className="container container-dealings text-start"
        style={{ height: "50vh" }}
      >
        <h3 className="pt-5 pb-3">Inclusion document</h3>
        <a href={pdfPresentation} target="_blank" rel="noreferrer">
          Inclusion document
          <FontAwesomeIcon className="ps-2" icon={faDownload} />
        </a>
      </div>
    </div>
  );
}

export default InclusionDocument;
