import React, { useEffect, useState } from "react";
import BGHero from "./BGHero";
import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function EthicsCode() {
  const { t } = useTranslation();

  const textHero = t("investorSection.bgHero");

  const [ethicalCode2024, setEthicalCode2024] = useState("");

  async function downlaodFile() {
    setEthicalCode2024(
      (
        await getUrl({
          key: "ethical_code/A8_codice_etico.pdf",
        })
      ).url.toString()
    );
  }

  useEffect(() => {
    downlaodFile();
  });

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div className="container container-azioni-soc text-start">
        <h3 className="pt-5">{t("values_ethics.ethic_code")}</h3>
        <div className="container mt-5 mb-5 p-0">
          <a href={ethicalCode2024} target="_blank" rel="noreferrer">
            {t("values_ethics.ethic_code")}{" "}
            <FontAwesomeIcon className="ps-2" icon={faDownload} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default EthicsCode;
