import React from "react";

function BGHero({ params }) {
  return (
    <div className="bg-image d-flex justify-content-center align-items-center rigsave-background-hero">
      <div className="container">
        <div className="mx-auto">
          <h2 className="pb-3">{params.text}</h2>
          <h3>{params.subtext}</h3>
        </div>
      </div>
    </div>
  );
}

export default BGHero;
