import React from "react";
import BGHero from "./BGHero";
import { useTranslation } from "react-i18next";
import TradingViewWidget from "./TradingViewWidget";

function AzioniSoc() {
  const { t } = useTranslation();

  const textHero = t("investorSection.bgHero");

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div className="container container-azioni-soc text-start">
        <h3 className="pt-5">{t("investorSection.azioniSoc")}</h3>
        <div className="container-stocks mb-5">
          <TradingViewWidget />
        </div>
      </div>
    </div>
  );
}

export default AzioniSoc;
