import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BGHero from "./BGHero";

import newsJson from "../json/news.json";

import financeNight1 from "../assets/news/Foto-Finance-Night-1.png";
import financeNight2 from "../assets/news/Foto-Finance-Night-2.png";

import circoloImprese1 from "../assets/news/Foto-Circolo-delle-Imprese_1.png";
import circoloImprese2 from "../assets/news/Foto-Circolo-delle-Imprese_2.png";

import adsKronos1 from "../assets/news/ads-kronos.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FacebookShare, LinkedinShare, TwitterShare } from "react-share-kit";
import YouTube from "react-youtube";
import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";

function News() {
  const { t, i18n } = useTranslation();

  const { newsId } = useParams();
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [youtube, setYoutube] = useState("");
  const [journal, setJournal] = useState("");
  const [corpNews, setCorpNews] = useState(false);
  const [files] = useState([]);
  const [imgsNews] = useState([
    {
      id: "financeNight",
      images: [
        {
          imgName: "financeNight1",
          file: financeNight1,
        },
        { imgName: "financeNight2", file: financeNight2 },
      ],
    },
    {
      id: "circoloImprese",
      images: [
        {
          imgName: "circoloImprese1",
          file: circoloImprese1,
        },
        { imgName: "circoloImprese2", file: circoloImprese2 },
      ],
    },
    {
      id: "adsKronos",
      images: [
        {
          imgName: "adsKronos1",
          file: adsKronos1,
        },
      ],
    },
  ]);
  const [fileExisting, setFileExisting] = useState(false);
  const [filePositioning, setFilePositioning] = useState(Object);
  const [imgs, setImgs] = useState([]);

  const videoReady = (event) => {
    event.target.pauseVideo();
  };

  async function downloadFile() {
    const pdfPressRelease = (
      await getUrl({
        key: "news/corporatenews_29112023_listing.pdf",
      })
    ).url.toString();

    const pdfPoolingVehicles = (
      await getUrl({
        key: "news/comunicatoStampa_17_poolingVehicles.pdf",
      })
    ).url.toString();

    const comunicatoStampa = {
      fileName: "comunicatoStampa",
      pdf: pdfPressRelease,
    };
    const poolingVehiclesFile = {
      fileName: "comunicatoStampa_17_poolingVehicles",
      pdf: pdfPoolingVehicles,
    };

    files.push(comunicatoStampa);
    files.push(poolingVehiclesFile);
  }

  useEffect(() => {
    downloadFile();
    const filtered = [...newsJson].find((flix) => {
      return flix.url === newsId;
    });

    if (filtered) {
      i18n.on("languageChanged", (language) => {
        setTitle(t("news.title." + newsId + ""));
        setText(t("news.text." + newsId + ""));
      });

      setTitle(t("news.title." + newsId + ""));
      setText(t("news.text." + newsId + ""));
      setCorpNews(filtered.corporateNews);
      if (filtered.youtube) {
        setYoutube(filtered.youtube);
      }
      if (filtered.giornale) {
        setJournal(filtered.giornale);
      }

      if (filtered.file) {
        setFileExisting(filtered.file.isInside);
        [...files].forEach((file) => {
          if (file.fileName === filtered.file.fileName) {
            setFilePositioning(file.pdf);
          }
          return file;
        });
      }

      if (filtered.imgs) {
        const img = imgsNews.find((flixImg) => {
          return flixImg.id === filtered.imgs;
        });

        setImgs(img.images);
      }
    }
  }, [title, files, newsId, imgsNews, i18n, t]);

  return (
    <div className="content-news content">
      <BGHero params={{ text: "" }} />
      <div className="container">
        <h2 className="page-title pt-5 mt-3 text-start">{title}</h2>
        <div className="d-lg-flex wrapper-news mt-5 pb-4">
          <div className="col-lg-2 col-sm-12 col-md-12 col-xs-12">
            <div className="page-date text-start">
              <p className="text-rigsave-color mb-1">Data</p>
              <p className="mt-1">{t("news.date." + newsId)}</p>
            </div>
            {journal && (
              <div className="page-journal text-start">
                <p className="text-rigsave-color mb-1">{t("news.newspaper")}</p>
                <p className="mt-1">{journal}</p>
              </div>
            )}
            <div className="share-news text-start">
              <FacebookShare
                round={true}
                size={40}
                url={window.location.href}
                quote={title}
              />
              <TwitterShare
                round={true}
                size={40}
                url={window.location.href}
                title={title}
              />
              <LinkedinShare
                round={true}
                size={40}
                url={window.location.href}
              />
            </div>
          </div>
          <div className="col-lg-10 col-sm-12 col-md-12 col-xs-12 text-start">
            {corpNews && (
              <div className="corp-news text-start mb-3">CORPORATE NEWS</div>
            )}
            {fileExisting && (
              <a
                href={filePositioning}
                className="text-start text-uppercase"
                target="_blank"
                rel="noreferrer"
              >
                {t("news.comunicatoStampa")}{" "}
                <FontAwesomeIcon icon={faDownload} />
              </a>
            )}
            {youtube && <YouTube videoId="PJEtH8FkevY" onReady={videoReady} />}
            <div
              className="project-text text-start mt-4"
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          </div>
        </div>
        {imgs.length > 0 && (
          <div className="news-wrapper-img mt-2 mb-5">
            {imgs.map((value, index) => {
              return (
                <img
                  key={index}
                  className="project-img mt-4 mb-4 mx-4"
                  src={value.file}
                  alt={value.imgName}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

//return <div>{newsId}</div>;

export default News;
