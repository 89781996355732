import React, { useState } from "react";
import PubblicationsDropdown from "./PubblicationsDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InvestorRelationDropdown = () => {
  const { t } = useTranslation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  return (
    <div className="dropdown-menu navbar-nav mx-auto pt-2">
      <ul
        className="nav-link-pubblications m-0 p-0"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <li className="nav-link text-rigsave-color">
          {t("main.header.subheaderInvestor.pubblications")}{" "}
          <FontAwesomeIcon icon={faChevronDown} />
        </li>
        {isDropdownVisible && <PubblicationsDropdown />}
        <li className="nav-link text-rigsave-color">
          <Link to={"/azioni"} className="text-rigsave-color">
            {t("main.header.subheaderInvestor.companyShares")}
          </Link>
        </li>
        <li className="nav-link text-rigsave-color">
          <Link to={"/shareholdersmeeting"} className="text-rigsave-color">
            {t("main.header.subheaderInvestor.shareholdersMeetings")}
          </Link>
        </li>
        <li className="nav-link text-rigsave-color">
          <Link to={"/ufficio-stampa"} className="text-rigsave-color">
            {t("main.header.subheaderInvestor.pressOffice")}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default InvestorRelationDropdown;
