import React from "react";

import companyLogo from "../assets/rigsavepng2.png";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TranslatorComponentMobile from "./TranslatorComponentMobile";

function HeaderMobile() {
  const { t } = useTranslation();

  return (
    <div className="header-mobile-container">
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img
              width={150}
              height={100}
              src={companyLogo}
              alt="Rigsave logo"
            />
          </Navbar.Brand>
          <TranslatorComponentMobile />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/about-us">{t("main.header.aboutUs")}</Nav.Link>
              <Nav.Link href="/governance">
                {t("main.header.governance")}
              </Nav.Link>
              <NavDropdown
                title={t("main.header.investorRelations")}
                className="dropdown"
              >
                <NavDropdown
                  title={t("main.header.subheaderInvestor.pubblications")}
                  className="dropend"
                >
                  <NavDropdown.Item
                    className="dropdown-item"
                    href="/presentations"
                  >
                    {t(
                      "main.header.subheaderInvestor.subheaderPubblications.presentations"
                    )}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="dropdown-item"
                    href="/director-dealings"
                  >
                    {t(
                      "main.header.subheaderInvestor.subheaderPubblications.directorDealings"
                    )}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="dropdown-item"
                    href="/financial-reports"
                  >
                    {t(
                      "main.header.subheaderInvestor.subheaderPubblications.financialReports"
                    )}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="dropdown-item"
                    href="/analyst-reports"
                  >
                    {t(
                      "main.header.subheaderInvestor.subheaderPubblications.analystReport"
                    )}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="dropdown-item"
                    href="/financial-calendar"
                  >
                    {t(
                      "main.header.subheaderInvestor.subheaderPubblications.financialCalendar"
                    )}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="dropdown-item"
                    href="/inclusion-document"
                  >
                    {t(
                      "main.header.subheaderInvestor.subheaderPubblications.inclusionDocument"
                    )}
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown.Item className="dropdown-item" href="/azioni">
                  {t("main.header.subheaderInvestor.companyShares")}
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="dropdown-item"
                  href="/shareholdersmeeting"
                >
                  {t("main.header.subheaderInvestor.shareholdersMeetings")}
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="dropdown-item"
                  href="/ufficio-stampa"
                >
                  {t("main.header.subheaderInvestor.pressOffice")}
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={t("values_ethics.title")}
                className="dropdown"
              >
                <NavDropdown.Item
                  className="dropdown-item"
                  href="/ethical-code"
                >
                  {t("values_ethics.ethic_code")}
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" href="/model-231">
                  {t("values_ethics.model_231")}
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/news-eventi">
                {t("main.header.newsEventi")}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default HeaderMobile;
