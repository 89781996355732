import React, { useEffect, useState } from "react";
import BGHero from "./BGHero";
import { Accordion, Placeholder } from "rsuite";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { getUrl } from "aws-amplify/storage";

function AssembleaAzionisti() {
  const { t } = useTranslation();

  const textHero = t("investorSection.bgHero");

  const [assembleaOttobre2023, setassembleaOttobre2023] = useState("");
  const [assembleaDicembre2023, setassembleaDicembre2023] = useState("");
  const [assembleaAprile2024, setassembleaassembleaAprile2024] = useState("");
  const [separateFinancialAudited2023, setSeparateFinancialAudited2023] =
    useState("");
  const [boardOfAuditorsBudget2023, setBoardOfAuditorsBudget2023] =
    useState("");
  const [assembleaAprileEN2024, setassembleaassembleaAprileEN2024] =
    useState("");

  async function downlaodFiles() {
    setassembleaOttobre2023(
      (
        await getUrl({
          key: "assemblee_soci/2023/20231005_117.pdf",
        })
      ).url.toString()
    );
    setassembleaDicembre2023(
      (
        await getUrl({
          key: "assemblee_soci/2023/Convocazione-Assemblea-soci-dicembre-2023.pdf",
        })
      ).url.toString()
    );
    setassembleaassembleaAprile2024(
      (
        await getUrl({
          key: "assemblee_soci/2024/assemblea_ordinaria_soci_29_Aprile_2024.docx",
        })
      ).url.toString()
    );
    setassembleaassembleaAprileEN2024(
      (
        await getUrl({
          key: "assemblee_soci/2024/shareholdes_meeting_29_April_2024.docx",
        })
      ).url.toString()
    );
    setSeparateFinancialAudited2023(
      (
        await getUrl({
          key: "financial_reports/2023/bilancio_separato_rigsavespa_revisionato_31_12_2023.pdf",
        })
      ).url.toString()
    );
    setBoardOfAuditorsBudget2023(
      (
        await getUrl({
          key: "assemblee_soci/2024/relazione _collegio_sindacale_bil_2023_rigsavespa.pdf",
        })
      ).url.toString()
    );
  }
  useEffect(() => {
    downlaodFiles();
  });

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div className="container container-analyst-reports text-start pt-2">
        <h3 className="pt-5 pb-3">
          {t("investorSection.shareholdersMeetings")}
        </h3>
        <div className="mb-5">
          <Accordion bordered>
            <Accordion.Panel header="2024" defaultExpanded>
              <a href={assembleaAprile2024} target="_blank" rel="noreferrer">
                {t("investorSection.callShareholdersShares")} 29{" "}
                {t("investorSection.april")} 2024 - IT
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={assembleaAprileEN2024} target="_blank" rel="noreferrer">
                {t("investorSection.callShareholdersShares")} 29{" "}
                {t("investorSection.april")} 2024 - EN
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <h5 className="mt-4 mb-0">
                {t("investorSection.docShareholdersMeetings")}
              </h5>
              <a
                href={separateFinancialAudited2023}
                target="_blank"
                rel="noreferrer"
              >
                {t("investorSection.separateFinancialStatements")}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={boardOfAuditorsBudget2023}
                target="_blank"
                rel="noreferrer"
              >
                {t("investorSection.reportBoard")}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel>
          </Accordion>
        </div>
        <div className="mb-5">
          <Accordion bordered>
            <Accordion.Panel header="2023" defaultExpanded>
              <a href={assembleaOttobre2023} target="_blank" rel="noreferrer">
                {t("investorSection.callShareholdersShares")} 24{" "}
                {t("investorSection.october")} 2023
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={assembleaDicembre2023} target="_blank" rel="noreferrer">
                {t("investorSection.callShareholdersShares")} 13{" "}
                {t("investorSection.december")} 2023
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel>
          </Accordion>
        </div>
      </div>
      <div className="container container-analyst-reports text-start pt-2">
        <h3 className="pt-5 pb-3">Corporate actions</h3>
        <div className="mb-5">
          <Accordion bordered>
            <Accordion.Panel header="2023" defaultExpanded>
              <Placeholder.Paragraph />
            </Accordion.Panel>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default AssembleaAzionisti;
