import React, { useEffect, useState } from "react";
import BGHero from "./BGHero";
import { Accordion } from "rsuite";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { getUrl } from "aws-amplify/storage";

function AnalystReport() {
  const { t } = useTranslation();

  const textHero = t("investorSection.bgHero");

  const [
    AnalystReport2024AlsterResearchURL,
    setAnalystReport2024AlsterResearchURL,
  ] = useState("");
  const [MWBResearchURL, setMWBResearchURL] = useState("");

  async function downlaodFile() {
    setAnalystReport2024AlsterResearchURL(
      (
        await getUrl({
          key: "analyst_reports/2024/26012024_research_alsterresearch.pdf",
        })
      ).url.toString()
    );
    setMWBResearchURL(
      (
        await getUrl({
          key: "analyst_reports/2024/2024_07_18_mwb_research.pdf",
        })
      ).url.toString()
    );
  }

  useEffect(() => {
    downlaodFile();
  });

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div className="container container-analyst-reports text-start pt-2">
        <h3 className="pt-5 pb-3">Analyst reports</h3>
        <div className="mb-5">
          <Accordion bordered>
            <Accordion.Panel header="2024" defaultExpanded>
              <a href={MWBResearchURL} target="_blank" rel="noreferrer">
                18/07/2024 - MWB Research
                <FontAwesomeIcon className="ps-2" icon={faDownload} />
              </a>
              <a
                href={AnalystReport2024AlsterResearchURL}
                target="_blank"
                rel="noreferrer"
              >
                26/01/2024 - AlsterResearch
                <FontAwesomeIcon className="ps-2" icon={faDownload} />
              </a>
            </Accordion.Panel>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
export default AnalystReport;
