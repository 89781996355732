import React from "react";
import BGHero from "./BGHero";
import { Timeline } from "rsuite";

import "../css/Home.css";
import "rsuite/dist/rsuite.min.css";

import visionImg from "../assets/Home-Mission-Vision.png";
import gruopImg from "../assets/Home-Gruppo-Rigsave.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import TradingViewWidget from "./TradingViewWidget";

function Home() {
  const { t } = useTranslation();

  const textHome = t("main.bgHero");

  const AlignTimeline = ({ align, ...props }) => (
    <Timeline {...props} align={align}>
      <Timeline.Item>
        <p>2023</p>
        <p className="text-rigsave-legal">{t("home.listing")} Rigsave S.p.A.</p>
        <p>{t("home.listingDescription")}</p>
      </Timeline.Item>
      <Timeline.Item>
        <p>2021</p>
        <p className="text-rigsave-legal">
          Rigsave Fund Sicav S.A ({t("home.productCompany")})
        </p>
        <p>{t("home.milestonesSection.fundSicav")}</p>
      </Timeline.Item>
      <Timeline.Item>
        <p>2020</p>
        <p className="text-rigsave-legal">Equity Crowdfunding Rigsave S.p.A.</p>
        <p>{t("home.milestonesSection.crowdfundingRigsavespa")}</p>
      </Timeline.Item>
      <Timeline.Item>
        <p>2020</p>
        <p className="text-rigsave-legal">
          Equity Crowdfunding Rigsave Tech S.r.l.
        </p>
        <p>{t("home.milestonesSection.crowdfundingRigsavetech")}</p>
      </Timeline.Item>
      <Timeline.Item>
        <p>2020</p>
        <p className="text-rigsave-legal">Rigsave S.p.A.</p>
        <p>{t("home.milestonesSection.rigsavespa")}</p>
      </Timeline.Item>
      <Timeline.Item>
        <p>2019</p>
        <p className="text-rigsave-legal">Rigsave Capital Ltd</p>
        <p>{t("home.milestonesSection.rigsavecapital")}</p>
      </Timeline.Item>
      <Timeline.Item>
        <p>2019</p>
        <p className="text-rigsave-legal">Rigsave Monthly Opportunity Bonds</p>
        <p>{t("home.milestonesSection.monthlyOpportunityBonds")}</p>
      </Timeline.Item>
      <Timeline.Item>
        <p>2019</p>
        <p className="text-rigsave-legal">Rigsave Tech S.r.l</p>
        <p>{t("home.milestonesSection.rigsaveTech")}</p>
      </Timeline.Item>
      <Timeline.Item>
        <p>2018</p>
        <p className="text-rigsave-legal">Rigsave Absolute Alpha Fund SLP</p>
        <p>{t("home.milestonesSection.absoluteAlphaFund")}</p>
      </Timeline.Item>
      <Timeline.Item>
        <p>2018</p>
        <p className="text-rigsave-legal">
          Pareto Securities Sarl ({t("home.productCompany")})
        </p>
        <p>{t("home.milestonesSection.paretoSecrities")}</p>
      </Timeline.Item>
    </Timeline>
  );

  return (
    <div id="home">
      <BGHero params={{ text: textHome }} />
      <div className="container container-stocks">
        <TradingViewWidget />
      </div>
      <div className="container container-vision d-lg-flex pb-5">
        <div className="col-4 img-vision-wrapper">
          <img src={visionImg} alt="Vision" />
        </div>
        <div className="col-5 pt-4">
          <h3>Mission</h3>
          <p>{t("home.mission")}</p>
          <h3>Vision</h3>
          <p>{t("home.vision")}</p>
        </div>
        <div className="col-3"></div>
      </div>
      <div className="container container-company-culture">
        <h3 className="pb-5">{t("home.valuesSection.values")}</h3>
        <div className="d-lg-flex justify-content-center">
          <div className="card me-5">
            <div className="card-body">
              <h3 className="pb-3">
                {t("home.valuesSection.innovationInclusion")}
              </h3>
              <p>{t("home.valuesSection.subInnovationInclusion")}</p>
            </div>
          </div>
          <div className="card ms-5 me-5">
            <div className="card-body">
              <h3 className="pb-3">{t("home.valuesSection.ethics")}</h3>
              <p>{t("home.valuesSection.subEthics")}</p>
            </div>
          </div>
          <div className="card ms-5">
            <div className="card-body">
              <h3 className="pb-3">{t("home.valuesSection.transparency")}</h3>
              <p>{t("home.valuesSection.subTransparency")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container container-milestones justify-content-center pt-5">
        <h3 className="pb-5">{t("home.milestones")}</h3>
        <AlignTimeline align="alternate" isItemActive={Timeline.ACTIVE_FIRST} />
      </div>
      <div className="container d-lg-flex container-gruop-rigsave">
        <div className="col-5">
          <h3 className="pb-3">{t("home.group")}</h3>
          <p>{t("home.groupDescription")}</p>
          <div className="button-link-group">
            <Link to={"/about-us"}>
              {t("home.findOutMore")} <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
        <div className="col-7 ps-5">
          <img src={gruopImg} alt="Group home" />
        </div>
      </div>
    </div>
  );
}

export default Home;
