import React from "react";
import { Link } from "react-router-dom";

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import companyLogoBianco from "../assets/Logo-Rigsave-bianco.png";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row row-footer-legal row-info pt-4">
          <div className="col-4">
            <div className="d-flex pb-1">
              <Link to={"/"} className="list-inline text-center">
                <img
                  src={companyLogoBianco}
                  className="company-logo-footer pb-1"
                  alt="Rigsave SpA logo"
                />
              </Link>
            </div>
            <p className="text-rigsave-legal">Rigsave SpA</p>
            <p>
              <FontAwesomeIcon icon={faLocationDot} /> C.so Giuseppe Zanardelli
              38, Brescia (BS)
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} /> 030 3756520
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> rigsave@pec.it
            </p>
          </div>
          <div className="col-6"></div>
          <div className="col-2">
            <div className="d-flex justify-content-center">
              <p className="text-rigsave-legal">SOCIAL</p>
            </div>
            <div className="d-flex justify-content-center">
              <Link to={"https://www.facebook.com/rigsave"}>
                <p className="px-1">
                  <FontAwesomeIcon icon={faFacebook} />
                </p>
              </Link>
              <Link to={"https://www.instagram.com/rigsave/"}>
                <p className="px-1">
                  <FontAwesomeIcon icon={faLinkedin} />
                </p>
              </Link>
              <Link
                to={"https://www.linkedin.com/company/rigsave-capital-sarl/"}
              >
                <p className="px-1">
                  <FontAwesomeIcon icon={faInstagram} />
                </p>
              </Link>
              <Link
                to={"https://www.youtube.com/channel/UCORzaiah8yhq5Q_iYd0uXVA"}
              >
                <p className="px-1">
                  <FontAwesomeIcon icon={faYoutube} />
                </p>
              </Link>
            </div>
          </div>
        </div>
        <div className="row row-footer-legal">
          <p>
            © 2022 Rigsave S.p.A. | Corso Zanardelli 38, 25121, Brescia, Italia,
            P.IVA 03690060987
          </p>
        </div>
        <div className="row row-footer-legal">
          <div className="justify-content-center d-flex">
            <Link
              to={"https://www.iubenda.com/privacy-policy/76849540"}
              className="nav-link px-5"
            >
              Privacy policy
            </Link>
            <Link
              to={
                "https://www.iubenda.com/privacy-policy/76849540/cookie-policy"
              }
              className="nav-link px-5"
            >
              Cookie Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
