import React, { useEffect, useState } from "react";
import BGHero from "./BGHero";

import "../css/InvestorRelation.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";

function Presentation() {
  const { t } = useTranslation();

  const textHero = t("investorSection.bgHero");

  const [pdfCompanyData, setPdfCompanyData] = useState("");
  const [pdfInfoMemorandum, setPdfInfoMemorandum] = useState("");

  async function downlaodFiles() {
    setPdfCompanyData(
      (
        await getUrl({
          key: "generic/Company_Data-RIGSAVE-SPA.pdf",
        })
      ).url.toString()
    );
    setPdfInfoMemorandum(
      (
        await getUrl({
          key: "generic/infomemo-Rigsave-SpA-eng.pdf",
        })
      ).url.toString()
    );
  }
  useEffect(() => {
    downlaodFiles();
  });

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div
        className="container container-dealings text-start"
        style={{ height: "50vh" }}
      >
        <h3 className="pt-5 pb-3">Presentation</h3>
        <div className="pb-3">
          <a href={pdfCompanyData} target="_blank" rel="noreferrer">
            Company data <FontAwesomeIcon className="ps-1" icon={faDownload} />
          </a>
        </div>
        <div className="pb-3">
          <a href={pdfInfoMemorandum} target="_blank" rel="noreferrer">
            Information memorandum
            <FontAwesomeIcon className="ps-1" icon={faDownload} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
