import React from "react";
import BGHero from "./BGHero";

import "../css/Governance.css";

import governanceAzionariato from "../assets/Governance-Azionariato.png";
import salvatoreGervasiImg from "../assets/Governance-CdA-Salvatore-Gervasi.png";
import micheleBasilicataImg from "../assets/Governance-CdA-Michele-Basilicata.png";
import collegioSindacaleImg from "../assets/Governance-Collegio-sindacale.png";
import revisoreContiImg from "../assets/Governance-Revisore-dei-conti-TBD.png";
import { Trans, useTranslation } from "react-i18next";

function Governance() {
  const { t } = useTranslation();

  const textHome = t("governanceSection.bgHero");

  return (
    <div id="governance">
      <BGHero
        params={{
          text: textHome,
        }}
      />
      <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
        <div className="card">
          <div className="card-body">
            <h3 className="pt-4 pb-3">
              {t("governanceSection.groupStrucure")}
            </h3>
            <div className="card-text pb-3 px-5">
              <Trans>{t("governanceSection.groupStrucureDescription")}</Trans>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
        <div className="rigsave-struttura-gruppo"></div>
      </div>
      <div className="container d-lg-flex cont-info justify-content-center align-items-center pt-5 pb-5">
        <div className="col-4 px-4">
          <p className="rig-title">RIGSAVE HOLDING LTD</p>
          <span>{t("governanceSection.financialHolding")}</span>
          <p className="pt-1">
            {t("governanceSection.financialHoldingDescription")}
          </p>
        </div>
        <div className="col-4 px-4">
          <p className="rig-title">RIGSAVE CAPITAL LTD</p>
          <span>{t("governanceSection.assetManagement")}</span>
          <p className="pt-1">
            {t("governanceSection.assetManagementDescription")}
          </p>
        </div>
        <div className="col-4 px-4">
          <p className="rig-title">RIGSAVE TECH</p>
          <span>{t("governanceSection.fintech")}</span>
          <p className="pt-1">{t("governanceSection.fintechDescription")}</p>
        </div>
      </div>
      <div className="container container-actions-rif d-lg-flex justify-content-start pt-5 pb-5">
        <div className="col-4 img-azion-wrapper">
          <div>
            <img src={governanceAzionariato} alt="Governance azionariato" />
          </div>
        </div>
        <div className="col-5 justify-content-start">
          <h3>{t("governanceSection.shareholdersStructure")}</h3>
          <div className="d-flex justify-content-between">
            <p>Salvatore Gervasi</p>
            <p className="ml-auto">
              {t("governanceSection.shareholder")} <span>18,21%</span>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Michele Basilicata</p>
            <p className="ml-auto">
              {t("governanceSection.shareholder")} <span>18,28%</span>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Rossana Ruffo</p>
            <p className="ml-auto">
              {t("governanceSection.shareholder")} <span>17,80%</span>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Tonino Gervasi</p>
            <p className="ml-auto">
              {t("governanceSection.shareholder")} <span>17,56%</span>
            </p>
          </div>
        </div>
        <div className="col-3"></div>
      </div>
      <div className="bg-grey container-ammin justify-content-center align-items-center">
        <div className="text-center">
          <h3>{t("governanceSection.directorBoard")}</h3>
        </div>
        <div className="container d-lg-flex container-img-ammin justify-content-center align-items-center pt-5">
          <div className="col-6">
            <img src={micheleBasilicataImg} alt="Michele Basilicata" />
            <h3>Michele Basilicata</h3>
            <p>{t("governanceSection.executiveDirector")}</p>
          </div>
          <div className="col-6">
            <img src={salvatoreGervasiImg} alt="Salvatore Gervasi" />
            <h3>Salvatore Gervasi</h3>
            <p>{t("governanceSection.executiveDirector")}</p>
          </div>
        </div>
      </div>
      <div className="container container-actions-rif d-lg-flex justify-content-start pt-5 pb-5">
        <div className="col-4 img-azion-wrapper">
          <div>
            <img src={collegioSindacaleImg} alt="Collegio sindacale" />
          </div>
        </div>
        <div className="col-5 justify-content-start">
          <h3>{t("governanceSection.auditorsBoard")}</h3>
          <div className="d-flex justify-content-between">
            <p>Vincenzo Atzori</p>
            <p className="ml-auto">{t("governanceSection.president")}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Luca Laurini</p>
            <p className="ml-auto">{t("governanceSection.eMember")}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Stefano Colao</p>
            <p className="ml-auto">{t("governanceSection.eMember")}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Silvia Molino</p>
            <p className="ml-auto">{t("governanceSection.sMember")}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Davide Barbieri</p>
            <p className="ml-auto">{t("governanceSection.sMember")}</p>
          </div>
        </div>
        <div className="col-3"></div>
      </div>
      <div className="container container-rev d-lg-flex pt-5">
        <div className="col-3"></div>
        <div className="col-5 justify-content-start">
          <h3>{t("governanceSection.auditor")}</h3>
          <div>
            <p>Prof.ssa Elisa Raoli</p>
            <p>{t("governanceSection.extAuditor")}</p>
          </div>
        </div>
        <div className="col-4 img-rev-wrapper">
          <img src={revisoreContiImg} alt="Revisore dei conti" />
        </div>
      </div>
    </div>
  );
}

export default Governance;
