import "./App.css";
import { Routes, Route } from "react-router-dom";
import React from "react";
import NotFound from "components/NotFound";
import Home from "components/Home";

import "bootstrap/dist/css/bootstrap.min.css";

import AboutUs from "components/AboutUs";
import Header from "components/Header";
import Footer from "components/Footer";
import Governance from "components/Governance";
//import Manifesto from "components/Manifesto";
import NewsEventi from "components/NewsEventi";
import AnalystReport from "components/AnalystReport";
import DirectorDealings from "components/DirectorDealings";
import FinancialReports from "components/FinancialReports";
import InclusionDocument from "components/InclusionDocument";
import Presentation from "components/Presentation";
import AzioniSoc from "components/AzioniSoc";
import AssembleaAzionisti from "components/AssembleaAzionisti";
import UfficioStampa from "components/UfficioStampa";
import FinancialCalendar from "components/FinancialCalendar";
import News from "components/News";

import { Suspense } from "react";
import { useMediaQuery } from "react-responsive";
import HeaderMobile from "components/HeaderMobile";
import EthicsCode from "components/EthicsCode";
import Model231 from "components/Model231";

function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <div className="App">
      {isDesktop && <Header />}
      {isTabletOrMobile && <HeaderMobile />}
      <div className="mt-3">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/governance" element={<Governance />} />
          <Route path="/news-eventi" element={<NewsEventi />} />
          <Route path="/presentations" element={<Presentation />} />
          <Route path="/director-dealings" element={<DirectorDealings />} />
          <Route path="/financial-reports" element={<FinancialReports />} />
          <Route path="/analyst-reports" element={<AnalystReport />} />
          <Route path="/financial-calendar" element={<FinancialCalendar />} />
          <Route path="/inclusion-document" element={<InclusionDocument />} />
          <Route path="/azioni" element={<AzioniSoc />} />
          <Route path="/shareholdersmeeting" element={<AssembleaAzionisti />} />
          <Route path="/ufficio-stampa" element={<UfficioStampa />} />
          <Route path="/news-eventi/:newsId" element={<News />} />
          <Route path="/ethical-code" element={<EthicsCode />} />
          <Route path="/model-231" element={<Model231 />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
}
