import React, { useEffect, useState } from "react";
import BGHero from "./BGHero";
import { Accordion } from "rsuite";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";

function FinancialCalendar() {
  const { t } = useTranslation();

  const textHero = t("investorSection.bgHero");

  const [financialCalendar2024, setFinancialCalendar2024] = useState("");

  async function downlaodFile() {
    setFinancialCalendar2024(
      (
        await getUrl({
          key: "financial_calendar/2024/Financial_Calendar-RIGSAVE-SPA.pdf",
        })
      ).url.toString()
    );
  }

  useEffect(() => {
    downlaodFile();
  });

  return (
    <div>
      <BGHero params={{ text: textHero }} />
      <div className="container container-analyst-reports text-start pt-2">
        <h3 className="pt-5 pb-3">Financial calendar</h3>
        <div className="mb-5">
          <Accordion bordered>
            <Accordion.Panel header="2024" defaultExpanded>
              <a href={financialCalendar2024} target="_blank" rel="noreferrer">
                Financial calendar 2024{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </Accordion.Panel>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default FinancialCalendar;
